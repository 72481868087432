<template>
    <card
        :ticket-id="ticket.ticketid"
        :order-id="ticket.orderid"
        @supportCardOpen="onSupportCardOpen"
    >
        <support-card-header
            :slot="'header'"
            :index="index"
            :subject="ticket.subject"
            :status="ticket.status_support"
            :order-id="ticket.orderid"
            :ticket-id="ticket.ticketid"
            :type="ticket.ticket_type"
            :resolution="ticket.ticket_resolution"
            :category="ticket.ticket_category"
            :bubles="unreadMessages"
            :is-open="isOpen"
            :create-date="ticket.created_at"
            :long-names-category="ticket.long_names && ticket.long_names.category"
            :long-names-resolution="ticket.long_names && ticket.long_names.resolution"
            :long-names-type="ticket.long_names && ticket.long_names.type"
            :last-message="ticket.last_message"
        />

        <support-card-body
            :slot="'content'"
            :ticket="ticket"
            :bubles="unreadMessages"
            @markAsRead="markAsRead"
        />
    </card>
</template>

<script>
import { mapGetters } from 'vuex'
import Api from '@/helpers/api/index.js'
import Card from '@/components/account/Cards/Card.vue'
import SupportCardHeader from '@/components/account/Cards/SupportCard/SupportCardHeader.vue'
import SupportCardBody from '@/components/account/Cards/SupportCard/SupportCardBody.vue'

export default {
    components: {
        Card,
        SupportCardHeader,
        SupportCardBody
    },
    props: {
        ticket: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            default: 1
        },
        type: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            unreadMessages: 0,
            isOpen: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ])
    },
    created() {
        if (this.type === 'guest') return
        this.unreadMessages = this.ticket.counters?.unread_messages_count || 0
    },
    methods: {
        async markAsRead(payload) {
            const isAuth = this.getterIsAuth ? 'auth' : 'guest'
            await Api.put(`/api/support/${isAuth}/mark-as-read`, { ...payload, hash: this.$route.query.hash })
                .then(() => {
                    setTimeout(() => {
                        this.$emit('markAsRead', {
                            unreadMessages: this.unreadMessages,
                            ticketid: payload.ticketid
                        })
                    }, 10000)
                })
            this.unreadMessages = 0
        },
        onSupportCardOpen(payload) {
            this.isOpen = payload
        }
    }
}
</script>
