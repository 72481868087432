<template>
    <div class="support-last-message">
        <header class="support-last-message__base-info">
            <span style="white-space: nowrap">
                Last message <span class="support-last-message__base-time">{{ message.created_at | moment_from }} ago</span>
            </span>
            <div class="support-last-message__person">
                from
                <div
                    class="support-last-message__person-wrapper"
                >
                    <avatar
                        :avatar-url="message.author.avatar_url"
                        :avatar-fallback="message.author.avatar_fallback"
                        :name="getName"
                        :user="message.author.type.toLowerCase()"
                    />
                    <p
                        v-if="message.author.type === 'Admin'"
                        class="support-last-message__person-name"
                    >
                        {{ message.author.name }} ({{ message.author.type }} {{ message.author.id }}):
                    </p>
                    <p
                        v-else
                        class="support-last-message__person-name"
                    >
                        You
                    </p>
                </div>
            </div>
        </header>
        <div class="support-last-message__message_details">
            « <span v-html="messageDetails" /> »
        </div>
    </div>
</template>

<script>
// Mixins
import filtersMixin from '@/mixins/filtersMixin.js'
// Components
import Avatar from '@/components/common/Avatar.vue'

export default {
    components: {
        Avatar
    },
    mixins: [
        filtersMixin
    ],
    props: {
        message: {
            type: Object,
            required: true
        },
        nickname: {
            type: String
        }
    },
    computed: {
        messageDetails() {
            return this.message.details.split('\n').join('<br>')
        },
        getName() {
            if (this.message.author.name === 'You') {
                return null
            }
            return this.message.author.name
        }
    }
}
</script>

<style lang="scss">
    .support-last-message{
        padding: 20px 0 10px;
        &__base-info{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: #abb1bb;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 1.5;
        }
        &__base-time{
            padding: 0 20px;
        }
        &__person{
            display: flex;
            align-items: center;
            .avatar-img{
                width: 26px;
                height: 26px;
            }
        }
        &__person-wrapper{
            padding: 0 20px;
            display: flex;
            align-items: center;
            white-space: nowrap
        }
        &__person-name{
            margin-left: 10px;
        }
        &__message_details{
            display: flex;
            font-style: italic;
            span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 1.3;
                padding: 0 5px;
            }
        }
    }
</style>
