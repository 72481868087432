<template>
    <div>
        <span class="ticket-header__subtopic-type">
            <span v-if="longNamesType">{{ longNamesType }}</span>
            <span v-if="longNamesCategory">{{ longNamesCategory }}</span>
            <span v-if="longNamesResolution">{{ longNamesResolution }}</span>
        </span>
        <div class="ticket-header">
            <h2 class="card_title ticket-header__title">
                <span class="card_title__index">
                    {{ `${index + 1}. ` }}
                </span>
                <div class="order-header__topic ticket-header__topic">
                    <span class="ticket-header__title-text">
                        «{{ subject }}»
                    </span>
                </div>
            </h2>
            <span class="ticket-header__timer">{{ createDate | moment_from }} ago</span>
            <div class="ticket-header__icons">
                <span class="order-header__bell">
                    <Bell v-if="bubles > 0" />
                    <img
                        v-else
                        src="@/assets/img/svg/bell-colorless.svg"
                        alt="bell"
                        width="36px"
                        height="36px"
                        class="mdi"
                    >
                    <span
                        v-if="bubles > 0"
                        class="bubble--unread bubble--unread-header"
                    >
                        {{ bubles }}
                    </span>
                </span>
            </div>
            <span class="ticket-header__status">
                <span
                    v-if="statusOpen"
                    class="card_title__status"
                    :class="{
                        'card_title__status--success': statusOpen === 'OPEN',
                        'card_title__status--error': statusOpen === 'CLOSED',
                    }"
                >
                    {{ statusOpen }}
                </span>
                <span
                    v-else
                    class="card_title__status"
                    :class="{
                        'card_title__status--success': status === 'OPEN',
                        'card_title__status--error': status === 'CLOSED',
                    }"
                >
                    {{ status }}
                </span>
            </span>
        </div>
        <transition name="openMessage">
            <div
                v-if="!isOpen"
                class="ticket-header__lastMessage"
            >
                <SupportLastMessage :message="lastMessage" />
            </div>
        </transition>
    </div>
</template>

<script>

import Bell from '@/components/icons/Bell'
import filtersMixin from '@/mixins/filtersMixin.js'
import { eventBus } from '@/helpers/event-bus/'

import { types } from '@/components/support/supportTypes.js'
import SupportLastMessage from '@/components/common/Chat/SupportLastMessage.vue'

export default {
    name: 'SupportCardHeader',
    components: {
        Bell,
        SupportLastMessage
    },
    mixins: [filtersMixin],
    props: {
        index: {
            type: Number,
            default: 1
        },
        subject: {
            type: String,
            default: 'Untitled'
        },
        status: {
            type: String,
            default: 'OPEN'
        },
        orderId: {
            type: String
        },
        type: {
            type: String
        },
        resolution: {
            type: String
        },
        category: {
            type: String
        },
        ticketId: {
            type: String
        },
        bubles: {
            type: Number,
            default: 0
        },
        createDate: {
            type: String
        },
        longNamesCategory: {
            type: String
        },
        longNamesResolution: {
            type: String
        },
        longNamesType: {
            type: String
        },
        lastMessage: {
            type: Object
        },
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            statusOpen: ''
        }
    },
    computed: {
        types() {
            return types
        }
    },
    mounted() {
        eventBus.$on('ChangeStatusOpen', (ticketIdClosed) => {
            if (this.ticketId === ticketIdClosed) {
                this.statusOpen = 'OPEN'
            }
        })
        eventBus.$on('ChangeStatusClosed', (ticketIdClosed) => {
            if (this.ticketId === ticketIdClosed) {
                this.statusOpen = 'CLOSED'
            }
        })
    },
    beforeDestroy() {
        eventBus.$off('ChangeStatusOpen')
        eventBus.$off('ChangeStatusClosed')
    },
    methods: {
        getFullNameType(type, payload) {
            return this.types[type].find((item) => (item.value === payload))?.text
        }
    }
}
</script>
<style lang="scss">
    .openMessage-enter,
    .openMessage-leave-to {
        opacity: 0;
        transform: rotateX(-90deg);
        transition: opacity .3s linear, transform 0.3s linear;
    }

    .openMessage-enter-to,
    .openMessage-leave {
        opacity: 1;
        transform: rotateX(0);
        transition: opacity .3s linear, transform 0.3s linear;
    }

</style>
