<template>
    <div
        ref="chat"
        class="chat"
    >
        <div
            ref="chat_wrapper"
            class="chat-wrapper"
        >
            <message
                v-for="(message, message_index) in messages"
                :key="message_index"
                :message="message"
            />
        </div>
        <send-message-form
            :last-message="lastMessage"
            :is-loading="isLoading"
            :status="ticket.status_support"
            :ticket-id="ticket.ticketid"
            @typing="typingListener"
            @sendMessage="sendMessage"
        >
            <p v-if="typingMessage">
                {{ typingMessage }}
            </p>
        </send-message-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

// Components
import Message from '@/components/common/Chat/Message.vue'
import SendMessageForm from '@/components/common/Chat/SendMessageForm.vue'

import {
    sendMessage, getSupportMessages
} from '@/services/support'

export default {
    components: {
        Message,
        SendMessageForm
    },
    props: {
        ticket: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            flag: true,
            messages: [],
            typing: false,
            typingMessage: ''
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterMainClientInfo'
        ]),
        lastMessage() {
            return this.messages[this.messages.length - 1]
        }
    },
    watch: {
        typing(newVal) {
            this.$socket.emit('support:typing', {
                typing: newVal,
                ticketid: this.ticket.ticketid
            })
        }
    },
    async created() {
        this.markAsRead()
        try {
            this.chatWSConnect()
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error');
        }
        await this.getMessages()
    },
    beforeDestroy() {
        this.$socket.emit('support:unsubscribe', { ticketid: this.ticket.ticketid })
    },
    methods: {
        async getMessages() {
            const data = await getSupportMessages({
                ticketid: this.ticket.ticketid,
                hash: this.$route.query.hash
            }, this.getterIsAuth)
            this.messages = data
            this.$nextTick(() => {
                this.scrollMessages()
            })
        },
        scrollMessages() {
            const elem = this.$refs.chat_wrapper
            elem.scrollTo({ top: 99999999, behavior: 'smooth' })
        },
        typingListener(value) {
            this.typing = value
        },
        async sendMessage({ message }) {
            try {
                this.isLoading = true;
                const data = await sendMessage({
                    ticketid: this.ticket.ticketid,
                    message,
                    hash: this.$route.query.hash
                }, this.getterIsAuth)
                eventBus.$emit('showSnackBar', 'Your message has been received', 'success');
                this.messages = [...this.messages, data]
                this.$nextTick(() => { this.scrollMessages() })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false;
            }
        },
        markAsRead() {
            this.$emit('markAsRead')
        },
        chatWSConnect() {
            this.$socket.emit('support:subscribe', { ticketid: this.ticket.ticketid, hash: this.$route.query.hash })
            this.$socket.on('support:newMessage', (data) => {
                if (data.author.id === this.getterMainClientInfo.id) return
                this.messages = [...this.messages, data]
                this.$nextTick(() => {
                    this.scrollMessages()
                })
            })
            this.$socket.on('support:typing', (data) => {
                if (data.role === 'Client' || data.role === 'Lead') return
                this.typingMessage = data.message
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .chat-wrapper{
        display: flex;
        flex-direction: column;
    }
    .chat{
        padding-bottom: 30px;
    }
</style>
