<template>
    <div
        class="card"
        :class="{'card-sideband' : $route.name === 'orders'}"
    >
        <div
            ref="card_header"
            class="card_header"
        >
            <slot
                name="header"
                :is-card-open="isCardOpen"
                :slide-card="slideCard"
            />
        </div>
        <transition name="slide">
            <div
                v-if="isCardOpen"
                class="card_slide"
            >
                <div
                    ref="card_content"
                    class="card_content"
                >
                    <slot name="content" />
                </div>
                <div
                    v-if="!!$slots.footer"
                    class="card_footer"
                >
                    <slot name="footer" />
                </div>
            </div>
        </transition>
        <div style="position: relative">
            <div
                v-if="showArrow"
                class="card_header-toggle"
                :class="{'card_header-toggle__order': $route.name === 'orders'}"
                @click.stop="slideCard"
            >
                <span
                    v-if="isCardOpen"
                    class="card_header-toggle__chevron"
                >
                    <chevron-up-icon />
                    <p class="card_header-toggle__text">Show less</p>
                </span>
                <span
                    v-else
                    class="card_header-toggle__chevron"
                >
                    <chevron-down-icon />
                    <p class="card_header-toggle__text">Show more</p>
                </span>
            </div>
            <div class="card_header-toggle__line" />
        </div>
    </div>
</template>

<script>
// Icons
import ChevronUpIcon from 'mdi-vue/ChevronUp'
import ChevronDownIcon from 'mdi-vue/ChevronDown'

export default {
    components: {
        ChevronUpIcon,
        ChevronDownIcon
    },
    props: {
        ticketId: {
            type: String,
            required: false
        },
        orderId: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            showArrow: true,
            isCardOpen: false
        }
    },
    mounted() {
        if (this.$route.query.ticketid === this.ticketId && this.ticketId !== undefined) {
            this.isCardOpen = true
        }
    },
    created() {
        // const checkCardType = this.$slots.header[0].componentOptions.tag
        // const isOrderDetails = this.$route.name.includes('order_') && checkCardType === 'order-card-header-new'
        // const isBillingDetails = this.$route.name === 'batch' && checkCardType === 'billing-card-header'
        // if (isOrderDetails || isBillingDetails) {
        //     this.showArrow = false
        //     this.isCardOpen = true
        // }
        if (this.$route.name.includes('order_')) {
            this.showArrow = false
            this.isCardOpen = true
        }
    },
    methods: {
        slideCard() {
            this.isCardOpen = !this.isCardOpen
            this.$emit('supportCardOpen', this.isCardOpen)
        }
    }
}
</script>
