import { render, staticRenderFns } from "./Сlipboard.vue?vue&type=template&id=a120c0fe&scoped=true&"
import script from "./Сlipboard.vue?vue&type=script&lang=js&"
export * from "./Сlipboard.vue?vue&type=script&lang=js&"
import style0 from "./Сlipboard.vue?vue&type=style&index=0&id=a120c0fe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a120c0fe",
  null
  
)

export default component.exports